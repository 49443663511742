import * as $ from 'jquery';

function initializeMenu () {
  const navbarToggler = $('.navbar-toggler');
  const navbarCollapse = $('.navbar-collapse');
  const navbarCollapseBackdrop = $('.navbar-collapse-backdrop');
  const navbarCollapseCloseButton = $('.navbar-collapse-close-bt');

  navbarToggler.on('click', showCollapseMenu);
  navbarCollapseBackdrop.on('click', hideCollapseMenu);
  navbarCollapseCloseButton.on('click', hideCollapseMenu);

  function showCollapseMenu() {
    navbarCollapse.addClass('navbar-collapse--show');
  }

  function hideCollapseMenu() {
    navbarCollapse.removeClass('navbar-collapse--show');
  }
}

export default initializeMenu();
