import * as $ from 'jquery';

var prices = {
  phone: 3999,
  fryer: 249,
  tv: 2119,
  perfume: 299
}

var commission = {
  beginner: 5,
  bronze: 6,
  silver: 6.7,
  gold: 7,
  diamond: 8
}

$(function () {
  var configStepper = {
    dots: $(window).width() < 650,
    infinite: false,
    speed: 300,
    centerMode: false,
    slidesToShow: 2,
    arrows: $(window).width() < 768 && $(window).width() > 650,
    adaptiveHeight: true
  }

  $('.commission-stepper').slick(configStepper);

  var valueProducts = $('#sumProduct');
  var valueComission = $('#commissionFinal');
  var sumProducts = 0

  function incrementValue (e) {
    var operator = $(e.target).prop('class').slice(15);
    var selectedProduct = $(e.target).parent().prop('class').slice(36);
    var valueOperator = operator === 'plus' ? prices[selectedProduct] : -prices[selectedProduct];
    var currentValue = parseInt(valueProducts.text().replace('R$ ', ''));
    var currentQuantity = parseInt($('.product-counter h4').text().replace('-', '0'))

    sumProducts = Math.max(currentValue + valueOperator, 0)
    valueProducts.text('R$ ' + sumProducts + ',00');
    var productQuantity = currentQuantity + (operator === 'plus' ? 1 : -1)

    if (sumProducts !== 0) {
      $('.product-counter').fadeOut(100).fadeIn(100);
    }

    if (sumProducts === 0 || productQuantity === 0) {
      $('.product-counter h4').text('-');
      $('.product-counter small').text('');

      valueProducts.text('R$ 0,00');

      sumProducts = 0
    } else {
      var productLabel = productQuantity > 1 ? 'produtos' : 'produto';

      $('.product-counter small').text(productLabel);
      $('.product-counter h4').text(productQuantity);
    }

    calculateCommission(null, $(".medals").find('.selected').prop('id'))

    return false;
  }

  function calculateCommission (e, medal) {
    $(".medals>div.selected").removeClass("selected");
    var selectedCommissionMedal = e === null ? medal : $(e.target).prop('id');

    if (selectedCommissionMedal) {
      $('#' + selectedCommissionMedal).addClass('selected');
      var selectCommissionValue = commission[selectedCommissionMedal]

      valueComission.text('R$ ' + Math.floor(sumProducts * (selectCommissionValue)/100) + ',00');
    } else {
      valueComission.text('R$ 0,00')
    }

    return false;
  }

  $('.comission-medal').on('click', calculateCommission)
  $('.plus').on('click', incrementValue);
  $('.minus').on('click', incrementValue);
});
