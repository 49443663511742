import * as $ from 'jquery';
import { faq } from './faq';

$(function () {
  function validateSlideQuantity() {
    if ($(window).width() > 960) return 3
    if ($(window).width() > 650) return 2

    return 1
  }

  var configStepper = {
    dots: $(window).width() < 650,
    infinite: false,
    speed: 300,
    centerMode: false,
    slidesToShow: validateSlideQuantity(),
    arrows: $(window).width() > 650,
    adaptiveHeight: true
  }

  $('.stepper').slick(configStepper);

  $('#faq-tabs-mobile').change(function () {
    var tabId = $(this).val();

    $(`#${tabId}`).tab('show');
  });

  const { categories, questions } = faq

  var renderCategories = categories.map(function ({ idCategory, title }, categoriesIndex) {
    var activeClass = categoriesIndex === 0 ? 'active' : '';
    var renderQuestions = '';

    questions.forEach(function ({ id, category, title, text }, questionIndex) {
      var showClass = questionIndex === 0 ? 'show' : '';

      if (category === idCategory) {
        renderQuestions = renderQuestions +  `
        <div class="card">
          <div class="card-header collapse-card-header" id="heading${id}" data-toggle="collapse" data-target="#accordion-${id}">
            <h5 class="mb-0">
              ${title}
            </h5>
          </div>
          <div id="accordion-${id}" class="collapse ${showClass}" data-parent="#ask-accordion-${category}">
            <div class="card-body">
              ${text}
            </div>
          </div>
        </div>
        `;
      };
    });

    var renderAccordians = `
      <div class="tab-pane ${activeClass}" id="tab-${idCategory}" role="tabpanel">
        <div class="accordion" id="ask-accordion-${idCategory}"></div>
          ${renderQuestions}
        </div>
      <div>
    `;

    $('#accordion-faq').append(renderAccordians);

    $('#faq-tabs-mobile').append($(`<option value="${idCategory}-tab">${title}</option>`));

    return `
      <li class="nav-item">
        <a
          class="nav-link ${activeClass}"
          id="${idCategory}-tab"
          data-toggle="tab"
          href="#tab-${idCategory}"
          role="tab"
        >
          ${title}
        </a>
      </li>
    `;
  });

  $('#faq-tabs').append(renderCategories);
})
