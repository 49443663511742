﻿export const faq = {
  categories: [
    {
      idCategory: "171d138606d",
      title: "Conheça o Influenciador Magalu"
    },
    {
      idCategory: "171e6e59f94",
      title: "Divulgação"
    },
    {
      idCategory: "171f8353ead",
      title: "Comissão"
    },
    {
      idCategory: "171ce694938",
      title: "Vendas"
    },
    {
      idCategory: "1715aa47f1d",
      title: "Cadastro"
    }
  ],
  questions: [
    {
      id: "3ffcc02b",
      category: "171e6e59f94",
      title: "Posso usar a marca do Magazine Luiza para divulgar minha loja?",
      text: "A reprodução dos nomes, títulos, palavras, frases, marcas, patentes, obras literárias, artísticas, lítero-musicais, imagens, dados e informações, dentre outras, está proibida, salvo com prévia autorização por escrito do Magazine Luiza ou caso se destinem ao uso exclusivamente pessoal e sem que em nenhuma circunstância os internautas e usuários adquiram qualquer direito sobre os mesmos."
    },
    {
      id: "2a54f942",
      category: "171e6e59f94",
      title: "Como faço para divulgar a minha loja?",
      text: "Assim que você cria sua loja já pode começar a divulgar e vender.Sabemos que tudo é muito novo e por isso temos muitos materiais explicando como fazer em nosso canal do youtube <a dataquery=\"#txtMedia4w4\">www.youtube.com.br/magazinevoce</a>, então a primeira dica é: assista todos os tutoriais. Lembre-se, toda divulgação é por sua conta e o resultado só vira se você se empenhar em entender nossa plataforma, assistir nossos vídeos e colocar em prática."
    },
    {
      id: "1720a15c095",
      weight: 5,
      title: "Como divulgar os produtos da loja em meu Facebook?",
      text: "Para divulgar sua loja para seus amigos no Facebook você pode compartilhar o link (endereço) da loja em seu Mural de Notícias, ou os produtos dela através do botão \"Compartilhar\", disponível em cada página de produto da sua loja (basta clicar no produto que você deseja compartilhar, e logo abaixo do campo de cálculo do Frete você encontra os botões sociais do Facebook, Twitter e Google Plus)",
      category: "171e6e59f94"
    },
    {
      id: "1720e5091eb",
      weight: 7,
      category: "171f8353ead",
      title: "Como é realizado o pagamento das comissões?",
      text: "Nosso modelo de pagamentos possui 2 datas fixas para o crédito de suas comissões. Essas datas serão, preferencialmente, nos dias 04 (quatro) e 19 (dezenove) de cada mês, lembrando que esta regra só vale se você acumular um crédito de pelo menos R$ 50,00 (cinquenta reais) em comissões. Mas para ficar mais claro, acompanhem o exemplo: Suponha que você venda um produto entre dias 01 e 15 do mês, se esse pedido tiver a nota fiscal emitida e o pagamento confirmado, você receberá a sua comissão no dia 04 do mês seguinte. Ou se você fizer uma venda entre os dias 16 e 30 do mês, receberá essa comissão no dia 19 do mês seguinte. Lembrando: Você poderá receber a sua comissão em no máximo 34 dias da data do pedido, desde que tenha a nota fiscal emitida e o pagamento confirmado. É importante lembrar o correto cadastramento do seu PIS e que a conta informada deve ser Corrente ou Poupança e vinculada ao CPF do divulgador (não serão aceitas Conta Salário, conjunta ou contas de terceiros). Do valor bruto da comissão, são descontados 11% relativo ao INSS, imposto obrigatório às empresas sobre serviços prestados por pessoas físicas. Quando o valor da comissão for superior a R$ 1.903,99, o Imposto de Renda deverá ser recolhido na fonte, conforme orientação da Medida Provisória nº 528 de 2011 e haverá uma dedução no valor da comissão."
    },
    {
      id: "171b033be35",
      weight: 8,
      category: "171f8353ead",
      title: "Há um valor mínimo para pagamento da minha comissão? Se eu não alcançar o valor mínimo para pagamento, perco o valor já acumulado de comissões?",
      text: "Sim. É necessário que você obtenha o valor mínimo de R$ 50,00 de comissão para que ela seja depositada em sua conta. Sua comissão fica acumulada até que, somada a outras vendas, atinja o valor mínimo de R$ 50,00."
    },
    {
      id: "1713f917ebc",
      weight: 9,
      category: "171f8353ead",
      title: "O pagamento da comissão pode atrasar?",
      text: "Caso haja alguma divergência de dados bancários e/ou os dados cadastrais não estiverem completos, pode ocorrer um atraso no pagamento de suas comissões."
    },
    {
      id: "17175757cf7",
      weight: 10,
      category: "171f8353ead",
      title: "Como controlo o pagamento das minhas comissões?",
      text: "Em seu Painel Controle, você poderá visualizar todos os pagamentos realizados."
    },
    {
      id: "171b954e595",
      weight: 11,
      category: "171f8353ead",
      title: "Minha comissão pode ser revertida em créditos para compras de produtos?",
      text: "Não há essa possibilidade, os pagamentos são realizados através de depósito em dinheiro por vendas faturadas."
    },
    {
      id: "1720e34cbb4",
      weight: 12,
      category: "171f8353ead",
      title: "Se o pedido realizado em minha loja for cancelado, recebo comissão?",
      text: "Não. A comissão referente a esse pedido será automaticamente cancelada."
    },
    {
      id: "171da66096d",
      weight: 13,
      category: "171f8353ead",
      title: "Com a arrecadação do imposto de 11% ao INSS, quais benefícios tenho direito?",
      text: "Os benefícios referente ao percentual recolhido são determinação legal e poderão ser esclarecidos diretamente na uma agência do INSS da sua cidade ou através do site <a href='https://www.inss.gov.br' rel='noreferrer noopener' target='_blank'>www.inss.gov.br</a>."
    },
    {
      id: "171la55096e",
      category: "171f8353ead",
      title: "Quais os impostos descontados das minhas comissões?",
      text: "Toda comissão que você receber terá um desconto de 11% referente ao INSS. Além disso, sua comissão pode ter um desconto referente ao Imposto de Renda caso atinja o valor mínimo de ganhos vigente da tabela do IRRF do respectivo ano. Para saber mais, acesse o link: <a href='https://bit.ly/2VP4y3m' target='_blank' rel='noreferrer noopener'>https://bit.ly/2VP4y3m</a>"
    },
    {
      id: "17147c604bb",
      weight: 14,
      category: "171ce694938",
      title: "1. Criei a loja, e agora, como faço pra vender?",
      text: "Assim que você cria sua loja já pode começar a divulgar e vender.Sabemos que tudo é muito novo e por isso temos muitos materiais explicando como fazer em nosso canal do youtube <a href='https://bit.ly/2Jk2rdJ' target='_blank' rel='noreferrer noopener'>https://bit.ly/2Jk2rdJ</a>, então a primeira dica é: assista todos os tutoriais. Lembre-se, toda divulgação é por sua conta e o resultado só vira se você se empenhar em entender nossa plataforma, assistir nossos vídeos e colocar em prática."
    },
    {
      id: "171fe9feff2",
      weight: 15,
      category: "171ce694938",
      title: "2. Os preços praticados no Influenciador Magalu são os mesmos preços do site do Magazine Luiza?",
      text: "Sim. Tanto os preços, quanto as campanhas realizadas no site do Magazine Luiza, valem também para as lojas do Influenciador Magalu e Magazine Você, mas eventualmente lançamos promoções exclusivas em nossa plataforma. Então, fique ligado na sua caixa de e-mail para acompanhar a divulgação das campanhas que enviamos."
    },
    {
      id: "171aa03f74c",
      weight: 16,
      category: "171ce694938",
      title: "3. Como faço para controlar o meu estoque de produtos?",
      text: "Você não precisa controlar o estoque pois é o mesmo do site e é atualizado automaticamente em sua loja. Se o produto se esgotar no site, ele também estará esgotado em sua loja."
    },
    {
      id: "17136d50adb",
      weight: 17,
      category: "171ce694938",
      title: "4. Preciso vender um valor mínimo para manter minha conta ativa?",
      text: "Não. Mas mantendo sua vitrine sempre atualizada e realizando a divulgação de sua loja você tem mais chances de fazer muitas vendas porque as pessoas lembrarão de você quando precisarem comprar algum produto."
    },
    {
      id: "171e3e5181d",
      weight: 18,
      category: "171ce694938",
      title: "5. Há um limite de produtos que posso vender em minha loja? Posso adicionar qualquer produto na minha vitrine?",
      text: "Não há limites de venda em sua loja e você pode adicionar qualquer produto que esteja à venda no site do Magazine Luiza."
    },
    {
      id: "171444cfe0e",
      weight: 19,
      category: "171ce694938",
      title: "6. Posso vender meus próprios produtos em minha loja?",
      text: "Não temos essa função ainda, mas hoje você contará com mais de 7 milhões de produtos disponíveis em sua loja"
    },
    {
      id: "1711aaeb145",
      weight: 20,
      category: "171ce694938",
      title: "7. O produto que estou tentando vender está esgotado, e agora?",
      text: "Alguns produtos podem ter o seu estoque esgotado devido à alta procura, porém, ao detectar a falta do produto o sistema bloqueia a venda automaticamente e não permite que a compra seja finalizada. Assim que esse produto volta ao estoque, estará automaticamente à disposição do cliente, não sendo necessário adicioná-lo novamente à sua vitrine."
    },
    {
      id: "171602559c8",
      weight: 21,
      category: "171ce694938",
      title: "8. Se um cliente comprar na minha loja, pode retirar o produto em uma loja física?",
      text: "Sim, temos uma opção que se chama Retira na loja, para saber como funciona, assista esse vídeo: <a href='https://bit.ly/2QSZ3Lo' target='_blank' rel='noreferrer noopener'>https://bit.ly/2QSZ3Lo</a>"
    },
    {
      id: "171bf13ddfa",
      weight: 22,
      category: "171ce694938",
      title: "9. Posso comprar em minha própria loja?",
      text: "Sim. Você pode comprar em sua loja como qualquer cliente e sua comissão será gerada normalmente. Mas para ganhar ainda mais, divulgue a sua loja."
    },
    {
      id: "1719a0ff9e9",
      weight: 23,
      category: "171ce694938",
      title: "10. Como funciona o cálculo do frete na minha loja?",
      text: "Os preços praticados pelo Influenciador Magalu e os valores de frete são os mesmos praticados no site do Magazine Luiza. Ao acessar a página do produto, antes mesmo de entrar no processo de compra do mesmo, ao inserir o CEP de entrega o cliente pode consultar o valor do frete e também o prazo de entrega do produto."
    },
    {
      id: "1718b3d979d",
      weight: 24,
      category: "171ce694938",
      title: "11. Meu cliente precisa cadastrar-se para realizar uma compra?",
      text: "Sim, ao realizar o processo de compra em sua loja virtual, o cliente deverá preencher um cadastro. No momento em que ele estiver finalizando o pedido, ele verá a opção \"cadastrar-se\" e deverá seguir os passos para criar o cadastro como cliente."
    },
    {
      id: "17125b7e332",
      weight: 25,
      category: "171ce694938",
      title: "12. A quem o cliente deve pagar pelo produto? Quem controla o pagamento dos pedidos feitos em minha loja?",
      text: "O cliente deverá pagar através das formas de pagamento disponíveis em sua loja. Não aceite efetuar o pagamento ou receber diretamente de seus clientes. Todo o processo de cobrança e pagamento é realizado pelo Magazine Luiza."
    },
    {
      id: "17200c8b299",
      weight: 26,
      category: "171ce694938",
      title: "13. Qual é o prazo para que um pedido efetuado em minha loja seja faturado?",
      text: "Os pedidos em sua loja tem um prazo de até 30 dias para aparecer com o status \"Faturado\", isso porque o sistema aguarda a total finalização do processo de compra (pagamento, recebimento e fim do prazo de devolução) para validar sua comissão, evitando quaisquer incovenientes."
    },
    {
      id: "171d6213e03",
      weight: 27,
      category: "171ce694938",
      title: "14. Porque os fretes do Magazine Luiza e Influenciador Magalu são mais altos para minha localização?",
      text: "O Magazine Luiza está trabalhando para diminuir os valores dos fretes na entrega de produtos no ecommerce. Já está em fase de implementação uma integração entre o site e todos os 13 centros de distribuição que temos espalhados pelo Brasil para possibilitar a entrega de produtos a partir do local mais próximo de você, tornando a entrega mais rápida e barata para qualquer localidade."
    },
    {
      id: "17162a4c3c1",
      weight: 28,
      category: "171ce694938",
      title: "15. Porque alguns produtos estão disponíveis, mas quando insiro o CEP de entrega ele fica como indisponível?",
      text: "O produto que o seu cliente está tentando comprar pode estar disponível para entrega apenas em algumas localidades. Nesse caso, os produtos podem aparecem como disponíveis e quando a pessoa coloca o CEP de entrega surge uma mensagem dizendo que para aquela localização específica ele não está disponível."
    },
    {
      id: "17212356e78",
      weight: 29,
      category: "171ce694938",
      title: "16. O produto está aparecendo com um valor diferente na vitrine da minha loja, o que eu faço?",
      text: "Nesses casos sempre leve o produto até o carrinho para confirmar o preço que está sendo praticado, pois algumas vezes ocorre de o preço ser diferente por conta do cache do seu navegador (o cache é um sistema automático de todos os navegadores da internet que armazena informações chamadas \"cookies\" e que precisa ser esvaziado periodicamente). Sempre que um preço estiver divergente verifique no carrinho para ter certeza do valor praticado, pois a regra do site Magazine Luiza, e consequentemente do Influenciador Magalu, é que o preço que vale é sempre o do carrinho."
    },
    {
      id: "17192cb8d14",
      weight: 30,
      category: "171ce694938",
      title: "17. O Influenciador Magalu faz a montagem dos móveis vendidos através da minha loja?",
      text: "Não. O Magazine Luiza não oferece o serviço de montagem de móveis."
    },
    {
      id: "172176fad56",
      weight: 31,
      category: "171ce694938",
      title: "18. Como faço para acompanhar as vendas realizadas em minha loja?",
      text: "Para ver suas vendas e suas comissões basta acessar o seu Painel Controle, e na aba \"Minhas Vendas\" você poderá controlar todas as vendas feitas através da sua loja"
    },
    {
      id: "17182d27cd7",
      weight: 32,
      category: "171ce694938",
      title: "19. Caso o meu cliente tenha dúvidas, quem realiza esse atendimento?",
      text: "Se o seu cliente já olhou olhou todas especificações do produto na ficha técnica e ainda assim tem dúvidas relativas à entrega, pagamento, troca, devolução ou itens que acompanham os produtos, ele deve entrar em contato com a Central de Atendimento do Magazine Luiza (0800 310 0002) ou pelo email atendimento.site@magazineluiza.com.br"
    },
    {
      id: "171d12b9362",
      weight: 33,
      category: "171ce694938",
      title: "20. O que é Garantia Estendida?",
      text: "A Garantia Estendida é um serviço oferecido pelo Magazine Luiza que garante um período de garantia adicional equivalente ao mesmo período de garantia oferecido pelo fabricante. Ou seja, se o fabricante oferece 1 ano de garantia na compra do produto, a garantia estendida garante mais 1 ano após o vencimento da garantia original (total: 2 anos de garantia). Além disso, ela inclui acesso à mão de obra especializada e profissionais credenciados, garantia de troca imediata em eletroportáteis com valor superior a R$ 600 e não apenas reparo. São mais de 3 mil assistências técnicas em todo Brasil, consertos com peças originais e atendimento exclusivo. O custo da Garantia Estendida para o cliente pode variar de acordo com o produto escolhido. O divulgador, ganha comissão em cima do valor do serviço contratado."
    },
    {
      id: "17124c4ba55",
      weight: 34,
      category: "171ce694938",
      title: "21. O que é Troca Certa?",
      text: "Assim como a Garantia Estendida, a Troca Certa é válida por um ano a mais do que a garantia oferecida pelo fabricante. Nesse caso, em caso de acionamento do benefício por defeito há uma substituição do produto por um novo ou similar. Mais segurança na compra dos produtos de qualquer marca, atendimento exclusivo e entrega em domicílio em todo Brasil. O divulgador também ganha comissão em cima do valor do serviço contratado."
    },
    {
      id: "1713624ecfd",
      weight: 35,
      category: "1715aa47f1d",
      title: "1. Quais são os pré requisitos para cadastrar-se no Influenciador Magalu?",
      text: "Quais são os pré requisitos para cadastrar-se no Magazine Você? Para criar sua loja, você precisa ser maior de 18 anos, possuir uma conta corrente ou poupança vinculada ao seu CPF e o número do PIS. Pessoas jurídicas e funcionários do Magazine Luiza não podem criar lojas."
    },
    {
      id: "171433a8efb",
      weight: 36,
      category: "1715aa47f1d",
      title: "2. Posso cadastrar uma conta salário ou conjunta para receber as comissões?",
      text: "Para que possamos fazer o pagamento das suas comissões é necessário que você tenha uma conta vinculada ao CPF cadastrado. Você pode abrir uma no MagaluPay em seu nome, através do SuperApp Magalu. Basta fazer o download do App Magalu na loja do seu smartphone, clicar no botão MagaluPay e seguir o passo a passo para preencher os dados. Acesse o link para mais informações: <a href='https://www.magazineluiza.com.br/portaldalu/magalupay-o-que-e/75203/' target='_blank' rel='noreferrer noopener'>https://www.magazineluiza.com.br/portaldalu/magalupay-o-que-e/75203/</a>."
    },
    {
      id: "171acf922f5",
      weight: 37,
      category: "1715aa47f1d",
      title: " 3. Tenho todos os pré-requisitos para me tornar um divulgador do Influenciador Magalu. O que preciso fazer agora?",
      text: "Cadastre-se agora mesmo, clicando no link: www.influenciadormagalu.com.br/cadastro. Você será redirecionado para uma página de cadastro, basta inserir as informações solicitadas e logo você será direcionado para a sua loja. É fácil e rápido!"
    },
    {
      id: "1711b57adce",
      weight: 38,
      category: "1715aa47f1d",
      title: "4. Quais são os dados que devo fornecer para realizar o meu cadastro no Influenciador Magalu?",
      text: "Para completar o cadastro, é necessário informar seu nome completo, um e-mail, telefone para contato, CPF, endereço e o número do PIS"
    },
    {
      id: "171fb25aab6",
      weight: 39,
      category: "1715aa47f1d",
      title: "5. É obrigatório fornecer o número do PIS?",
      text: "Sim, sem o número do PIS não temos como efetuar o pagamento das comissões. Esse dado é necessário pois, ao ser atingido o valor mínimo para pagamento da comissão, são deduzidos 11% referente ao INSS, imposto que as empresas são obrigadas a recolher sobre serviços prestados por pessoas físicas. O PIS pode ser encontrado anotado ao final da sua carteira de trabalho ou no Cartão Cidadão"
    },
    {
      id: "171499674ee",
      weight: 40,
      category: "1715aa47f1d",
      title: "6. Como saberei se meu cadastro foi aprovado?",
      text: "Seu cadastro é aprovado após você ter assistido o vídeo de boas vindas. Assim que sua loja aparecer na tela, você já poderá vender"
    },
    {
      id: "171e5a547fb",
      weight: 41,
      category: "1715aa47f1d",
      title: "7. Como faço para atualizar e editar o meu cadastro?",
      text: "Acesse o site do Influenciador Magalu, faça o login com seu email e senha e ao acessar o seu Painel de Controle no canto direito superior da tela, clique em \"Meus Dados\"."
    },
    {
      id: "1717d3bd1cf",
      weight: 42,
      category: "1715aa47f1d",
      title: "8. Como faço para alterar o nome da minha loja?",
      text: "A mudança do nome da loja apenas será possível no dia da criação da mesma, após esse dia, não será mais possível a alteração do nome"
    },
    {
      id: "171b210ca02",
      weight: 43,
      category: "1715aa47f1d",
      title: "9. Quais são as especificações da imagem de perfil?",
      text: "O tamanho recomendado para foto da imagem de perfil é de 150x150 pixels."
    },
    {
      id: "171ad4c4f3a",
      weight: 44,
      category: "1715aa47f1d",
      title: "10. Adicionei os meus dados bancários, mas gostaria de alterar. Como devo fazer?",
      text: "Se você nunca recebeu um pagamento, basta alterar na seção Meus Dados, presente no seu Painel de controle. Mas caso já tenha recebido a comissão alguma vez, além de alterar os dados no seu painel de controle, você deve clicar no botão Falar com Suporte e preencher as informações para solicitar a alteração no seu cadastro."
    },
    {
      id: "1720f7cc503",
      weight: 45,
      category: "1715aa47f1d",
      title: "11. Eu preciso comprar os produtos para revendê-los?",
      text: "Não. O estoque dos produtos fica 100% no Magazine Luiza. Você só precisa indicar os produtos para os seus amigos através da sua loja e realizar os pedidos. A entrega será realizada pela equipe do Magazine Luiza."
    },
    {
      id: "1714a35160a",
      weight: 46,
      category: "1715aa47f1d",
      title: "12. Posso desabilitar meu cadastro quando desejar? Haverá alguma multa caso eu opte por cancelar?",
      text: "O cadastro pode ser cancelado a qualquer momento. Em seu Painel de Controle, na aba \"Meus Dados\" você encontra a opção de \"Desativar Loja\". Não há nenhum custo de cancelamento da loja e nem por mantê-la ativa sem uso."
    },
    {
      id: "1716d971259",
      weight: 47,
      category: "1715aa47f1d",
      title: "13. Cancelei a minha loja mas gostaria de voltar a trabalhar com ela. Como faço agora?",
      text: "Em seu Painel de Controle selecione a opção de Ativar sua loja, caso a opção não esteja disponível não será possível a ativação de sua loja. Deverá verificar seu e-mail para identificar o comunicado referente a desativação."
    },
    {
      id: "171328d55de",
      weight: 48,
      category: "171d138606d",
      title: "1. O que é o Influenciador Magalu?",
      text: "O Influenciador Magalu é a sua loja de produtos online do Magalu, que conta toda a estrutura da empresa Magazine Luiza. Aqui, você monta uma loja com a sua cara e vende os produtos do Magalu para seus amigos, por meio de suas redes sociais, apps de mensagem ou em suas comunidades - para receber  comissão por cada produto vendido. Super oportunidade de ter seu próprio negócio digital, e contando com a entrega do pedido, atendimento e pós-vendas do Magazine Luiza."
    },
    {
      id: "171b002e592",
      weight: 50,
      category: "171d138606d",
      title: "2. Qual é o custo da loja?",
      text: "Criar uma loja no Influenciador Magalu é totalmente grátis, você não precisa realizar nenhum investimento inicial ou comprar produtos para revender. Basta cadastrar-se, criar a sua loja e começar a vender para começar a lucrar."
    },
    {
      id: "1715eeb84a4",
      weight: 51,
      category: "171d138606d",
      title: "3. Posso ter mais de uma loja?",
      text: "Não. É possível criar apenas uma loja para cada CPF."
    },
    {
      id: "1712853d320",
      weight: 52,
      category: "171d138606d",
      title: "4. Aposentados podem ter uma loja no Influenciador Magalu?",
      text: "A aposentadoria não é impeditivo para a divulgação no Influenciador Magalu, exceto as aposentadorias ou benefícios adquiridas através de incapacidades de trabalho. O Magazine Luiza informa ainda que toda e qualquer dúvida com relação a eventuais benefícios recebidos pelo segurado, poderão ser sanadas diretamente junto à Agencia do INSS de sua cidade. Esclarece ainda que o Magazine Luiza não se responsabiliza por qualquer perda ou suspensão do benefício concedido."
    },
    {
      id: "1714b91cdea",
      weight: 53,
      category: "171d138606d",
      title: "5. Estou recebendo Seguro-Desemprego, auxílio-doença ou bolsa família pelo INSS, posso criar uma loja",
      text: "Todo e qualquer benefício concedido pelo INSS são regidos por normas próprias de concessão, assim como regras claras de suspensão. O seguro desemprego será suspenso quando o beneficiário deixar de cumprir as condicionalidades do programa, principalmente a de não possuir renda própria de qualquer natureza suficiente à sua manutenção e de sua família. O auxilio doença é concedido para beneficiários que estejam com incapacidade laboral e o bolsa família tem regras claras e específicas para concessão e suspensão, conforme poderá ser consultado no site https://www.beneficiossociais.caixa.gov.br/consulta/beneficio/04.01.00-00_00.asp. O Magazine Luiza informa ainda que toda e qualquer dúvida com relação a eventuais benefícios recebidos pelo segurado, poderão ser sanadas diretamente junto à Agencia do INSS de sua cidade. Esclarece ainda que o Magazine Luiza não se responsabiliza por qualquer perda ou suspensão do benefício concedido."
    },
    {
      id: "1720e255c70",
      weight: 54,
      category: "171d138606d",
      title: "6. Como faço pra virar um Diamante? E um TOP Influenciador Magalu? ",
      text: "Na plataforma, quanto mais você divulga e vende sua loja, maior será sua classificação no ranking de qualificação para se tornar um Influenciador Diamante. Para conquistar o Nível TOP Influenciador, você precisa ter uma loja ativa no Influenciador Magalu e respeitar nossos Termos de Uso; ter relevância nas vendas de uma ou mais categorias de produtos e, claro, ser muito engajado nas redes sociais, não havendo uma exigência de volumetria mínima ou máxima de seguidores nos perfis sociais/oficiais. O engajamento do Influenciador Magalu será avaliado com base em escala interna, por parte do time interno e a inscrição de ve ser realizado https://especiais.magazineluiza.com.br/pmd-influenciador/"
    },
    {
      id: "171af62a659",
      weight: 55,
      category: "171d138606d",
      title: "7. Há diferentes classificações para as lojas? Como funciona isso?",
      text: "Sua qualificação é definida por diversos fatores. Ela fica visível para seus clientes no alto da sua loja. Subir no ranking depende de você e pode te ajudar a vender muito mais."
    },
    {
      id: "1713912377b",
      weight: 56,
      category: "171d138606d",
      title: "8. O que preciso fazer para mudar a minha categoria de ranking?",
      text: "Você precisa aumentar sua qualificação. Vendas faturadas e avaliação dos clientes na conclusão da compra são atividades do processo de compra, e por isso têm maior peso para o aumento da sua qualificação. Já a divulgação nas redes sociais e a criação de conteúdo também ajudam. Para conferir sua evolução basta olhar o quadro de Atividades recentes e a pontuação que está no canto direito do Painel de Controle da sua loja."
    },
    {
      id: "17217fe0811",
      weight: 57,
      category: "171d138606d",
      title: "9. O que eu ganho com isso?",
      text: "A sua classificação no ranking define a porcentagem de comissão que você receberá sobre a venda dos produtos. Além disso, você irá passar ainda mais confiança para seu cliente e terá acesso a benefícios exclusivos, dependendo da pontuação."
    },
    {
      id: "17200bfbf83",
      weight: 58,
      category: "171d138606d",
      title: "10. Fiz uma venda em minha loja e não calculou a pontuação, porque?",
      text: "A pontuação sobre a venda do produto só é contabilizada após o faturamento da compra, ou seja, você irá verificar seus pontos após os 30 dias do faturamento."
    },
    {
      id: "171fd2f7e50",
      weight: 59,
      category: "171d138606d",
      title: "11. Existem vídeos que ensinam a usar a plataforma e em como vender?",
      text: "Sim, temos um canal no youtube com vídeos explicando cada função de sua loja, tutorias para te ajudar a vender e muitas dicas de como alimentar e engajar suas redes sociais, Acesse já e inscreva-se: https://www.youtube.com/@InfluenciadorMagalu"
    },
    {
      id: "171adfe5c7c",
      weight: 61,
      category: "171d138606d",
      title: "12. Tenho uma empresa, posso vender no Influenciador Magalu",
      text: "O Influenciador Magalu é para pessoa física, mas se você possui uma empresa temos o Parceiro Magalu empresa com outras vantagens, você pode vender seus produtos no site do magalu, emitir as notas fiscais automaticamente e outras vantagens, para saber mais acesse: <a href='https://www.parceiromagalu.com/empresa' target='_blank' rel='noreferrer noopener'>https://www.parceiromagalu.com/empresa</a>"
    }
  ]
}
